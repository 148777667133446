import React, { ReactNode } from 'react';
import { ModuleSpacing } from '~/lib/data-contract';
import { Gutter } from '~/shared/components/Gutter';
import { breaks } from '~/theme';
import { useThemeShade } from '~/theme';
import { StyledModule } from './styled';

export type ModuleContainerProps = {
    /**
     * Adds a solid background color to the entire module space.
     * Edge to edge.
     */
    backgroundColor?: string;

    spacingTop?: ModuleSpacing['spacingTop'];
    spacingBottom?: ModuleSpacing['spacingBottom'];

    /**
     * Adds side gutters to the module. Useful if the content
     * is not intended to go full width on mobile. eg. text modules.
     * Supply a key from 'breaks', to only have gutter from supplied breakpoint
     */
    hasGutter?: keyof typeof breaks | boolean;

    children: ReactNode;

    hideMobile?: boolean;
    hideDesktop?: boolean;
};

export const ModuleContainer = ({
    children,
    hasGutter,
    spacingTop,
    spacingBottom,
    backgroundColor: initialBackgroundColor,
    hideMobile,
    hideDesktop,
}: ModuleContainerProps) => {
    const { backgroundColor } = useThemeShade({
        backgroundColor: initialBackgroundColor,
    });

    const content = hasGutter ? (
        <Gutter children={children} gutterFrom={hasGutter === true ? undefined : hasGutter} />
    ) : (
        children
    );

    return (
        <StyledModule
            backgroundColor={backgroundColor}
            spacingBottom={spacingBottom || 'default'}
            spacingTop={spacingTop || 'default'}
            hideMobile={hideMobile}
            hideDesktop={hideDesktop}
        >
            {content}
        </StyledModule>
    );
};
