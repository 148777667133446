import dynamic from 'next/dynamic';
import React, { ComponentType } from 'react';
import { Modules } from '~/lib/data-contract';
import { ErrorBoundary } from '~/shared/utils/errorBoundary/ErrorBoundary';
import { ErrorFallbackContent } from '../ErrorFallbackContent';

type ModuleType = NonNullable<Modules['type']>;

export type Blocks = {
    [K in ModuleType]?:  // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | ComponentType<any>
        | (({ children }: { children?: ComponentType; index?: number }) => JSX.Element);
};

const Noop = ({ children }: { children: ComponentType }) => <>{children}</>;

const modules: Blocks = {
    m0LineModule: dynamic(() => import('../M0Line')),
    m10HeroModule: dynamic(() => import('../M10Hero')),
    m11HeroModule: dynamic(() => import('../M11Hero')),
    m12HeroModule: dynamic(() => import('../M12Hero')),
    m20CategoriesModule: dynamic(() => import('../M20Categories')),
    m22CategoriesModule: dynamic(() => import('../M22Categories')),
    m23PlpBottomCategoriesModule: dynamic(() => import('../M23PlpBottomCategories')),
    m30ProductCarouselModule: dynamic(() => import('../Carousel/blocks/M30ProductCarousel'), {
        ssr: false,
    }),
    m31ProductCarouselModule: dynamic(() => import('../Carousel/blocks/M31ProductCarousel'), {
        ssr: false,
    }),
    m32ProductCarouselModule: dynamic(() => import('../Carousel/blocks/M32ProductCarousel'), {
        ssr: false,
    }),
    m40PromotedContentModule: dynamic(() => import('../M40PromotedContent')),
    m60UspModule: dynamic(() => import('../M60USP')),
    m61UspModule: dynamic(() => import('../M61USP')),
    m70MediaModule: dynamic(() => import('../M70Media')),
    m75MediaAndTextModule: dynamic(() => import('../M75MediaText')),
    m76MediaAndTextModule: dynamic(() => import('../M76MediaText')),
    m80MediaAndTextBundleModule: dynamic(() => import('../M80MediaTextBundle')),
    m90QuoteModule: dynamic(() => import('../M90Quote')),
    m100RichTextModule: dynamic(() => import('../M100RichText')),
    m110NewsletterSignupModule: dynamic(() => import('../M110NewsletterSignup')),
    m120AccordionListModule: dynamic(() => import('../M120Accordion')),
    m130ProductDetailModule: dynamic(() => import('../ProductDetail/M130ProductDetail')),
    m131ProductDetailModule: dynamic(() => import('../ProductDetail/M131ProductDetail')),
    m140ProductListModule: dynamic(() => import('../M140ProductsList')),
    m150BasketModule: dynamic(() => import('../M150Basket'), { ssr: false }),
    m160CheckoutModule: dynamic(() => import('../M160Checkout'), { ssr: false }),
    m170SearchResultsModule: dynamic(() => import('../M170Search')),
    m180CookiePolicyModule: dynamic(() => import('../M180CookiePolicy'), { ssr: false }),
    m190ReceiptModule: dynamic(() => import('../M190Receipt'), { ssr: false }),
    m200ProfileModule: dynamic(() => import('../M200Profile')),
    m210SearchbarModule: dynamic(() => import('../M210Searchbar')),
    m220ReturnsModule: dynamic(() => import('../M220Returns')),
};

type DynamicBlockProps = {
    element: Modules;
    index?: number;
};

export const DynamicBlock = ({ element }: DynamicBlockProps) => {
    const Block = modules[element.type as keyof Blocks] || Noop;
    return (
        <ErrorBoundary fallback={<ErrorFallbackContent />}>
            <Block {...element} />
        </ErrorBoundary>
    );
};
