import React, { PropsWithChildren } from 'react';
import { useError } from '~/shared/utils/errorBoundary/hooks/useError';
import { useTranslation } from '~/shared/utils/translation';
import { ModuleContainer } from '../ModuleContainer';
import { StyledErrorContent } from './styled';

export const ErrorFallbackContent = ({ children }: PropsWithChildren) => {
    const { translate } = useTranslation();
    const { error } = useError();

    return (
        <ModuleContainer>
            <StyledErrorContent>
                <div>{children ? children : translate('message.contentUnavailable')}</div>
                <div>Error: {error?.message}</div>
            </StyledErrorContent>
        </ModuleContainer>
    );
};
