import React from 'react';
import { Modules } from '~/lib/data-contract';
import { ModuleContext } from '../../context/ModuleContext';
import { DynamicBlock } from '../DynamicBlock';

type Props = {
    elements: Modules[];
};

export const DynamicBlockList = ({ elements }: Props) => {
    if (!elements.length) {
        return null;
    }

    return (
        <>
            {elements.map((element, index) => (
                <ModuleContext.Provider value={{ index, element }} key={element.id}>
                    <DynamicBlock element={element} />
                </ModuleContext.Provider>
            ))}
        </>
    );
};
