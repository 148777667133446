import styled from '@emotion/styled';

export const StyledErrorContent = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.colors.light30,
    width: '100%',
    padding: '10vh 0',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spaces[4],
}));
