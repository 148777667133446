import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints, breaks } from '~/theme';

export const StyledGutter = styled.div<{ gutterFrom?: keyof typeof breaks }>(
    ifProp(
        'gutterFrom',
        ({ theme, gutterFrom }) => ({
            [breakpoints[gutterFrom as keyof typeof breaks]]: {
                paddingLeft: theme.gridConfig.gutter,
                paddingRight: theme.gridConfig.gutter,
            },
        }),
        ({ theme }) => ({
            paddingLeft: theme.gridConfig.gutter,
            paddingRight: theme.gridConfig.gutter,
        })
    )
);
