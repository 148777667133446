import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ModuleSpace } from '~/lib/data-contract';
import { getClampFullRange } from '~/theme/utils/getClampValue';
import { ModuleContainerProps } from './ModuleContainer';
import { breakpoints } from '$theme';

type SpaceingValues = keyof Theme['spaces'] | undefined;

const spacings: Record<ModuleSpace, { [key in 'mobile' | 'desktop']: SpaceingValues }> = {
    default: {
        mobile: '4',
        desktop: '6',
    },
    large: {
        mobile: '5',
        desktop: '8',
    },
    none: {
        mobile: undefined,
        desktop: undefined,
    },
};

const spacingValue = (spacing: ModuleSpace, theme: Theme) => {
    const value = spacings[spacing];
    return value?.mobile && value?.desktop
        ? getClampFullRange(theme.spacesRaw[value.mobile], theme.spacesRaw[value.desktop])
        : 0;
};

export const StyledModule = styled.div<ModuleContainerProps>(
    ({
        theme,
        spacingTop = 'default',
        spacingBottom = 'default',
        backgroundColor,
        hideMobile,
        hideDesktop,
    }) => ({
        paddingTop: spacingValue(spacingTop, theme),
        paddingBottom: spacingValue(spacingBottom, theme),
        margin: '0 auto',
        backgroundColor,
        display: !hideMobile ? 'block' : 'none',
        [breakpoints.md]: {
            display: !hideDesktop ? 'block' : 'none',
        },
    })
);
