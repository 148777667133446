import { createContext } from 'react';
import { ErrorFallbackProps } from '../model/ErrorBoundaryModel';

export const ErrorBoundaryContext = createContext<ErrorFallbackProps>({
    error: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onReset: () => {},
});

type ErrorBoundaryProviderProps = {
    contextValue: {
        error: Error | null;
        onReset: () => void;
    };
};

export const ErrorBoundaryProvider = ({
    children,
    contextValue,
}: React.PropsWithChildren<ErrorBoundaryProviderProps>) => {
    return (
        <ErrorBoundaryContext.Provider value={contextValue}>
            {children}
        </ErrorBoundaryContext.Provider>
    );
};
